import Vue from 'vue'
import ElementUI  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router/index'
import './assets/css/base.css';
import './assets/css/animate.min.css';
import './assets/css/owl.carousel.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
	import 'swiper/dist/css/swiper.css'
	Vue.use(VueAwesomeSwiper)

// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'

// Vue.use(Viewer);
// Viewer.setDefaults({
//     'inline':true,
//     'button':true, //右上角按钮
//     "navbar": true, //底部缩略图
//     "title": true, //当前图片标题
//     "tooltip": true, //显示缩放百分比
//     "movable": true, //是否可以移动
//     "zoomable": true, //是否可以缩放
//     "transition": true, //使用 CSS3 过度
//     "fullscreen": true, //播放时是否全屏
//     "url": "data-source",
//   });
Vue.config.productionTip = false
Vue.use(ElementUI)
/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
new Vue({
  router:router,

  render: h => h(App)
}).$mount("#app")
