<template>
  <div class="footer wow fadeInUp">
    <div class="rowFluid">
      <div class="span12">
        <div class="container">
          <div class="footer_content">
            <div class="span4 col-xm-12 foot-left">
              <div class="footer_list">
                <div class="span6">
                  <div class="bottom_logo">
                    <div class="quick_navigation_title">
                      成为安徽成方的合作伙伴
                    </div>
                    <img :src="picData[0]" alt="成为安徽成方合作伙伴" />
                  </div>
                </div>
                <div class="span6 col-xm-12">
                  <div class="quick_navigation">
                    <div class="quick_navigation_title">快速导航</div>
                    <ul>
                      <li><a href="/" title="首页">首页</a></li>
                      <li>
                        <a href="/NewsList" 
                          >公司简介</a
                        >
                      </li>
                      <li>
                        <a href="/soultionList" 
                          >产品</a
                        >
                      </li>
                      <li>
                        <a
                          href="/technology/mobileSdk"
                          >核心技术</a
                        >
                      </li>
                      <li>
                        <a href="/JobList" 
                          >联系我们</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="span4 col-xm-6 col-xs-12">
                    <div class="footer_list">
                    <div class="footer_link">
                        <div class="footer_link_title">友情链接：</div>
                        <ul id="frientLinks">
                            <li><a href='http://www.retechcorp.com/zh-CN/Home/Profile' target='_blank'>江苏睿泰教育科技有限公司</a> </li>
                            <li><a href='http://www.ahzx.info/' target='_blank'>安徽中寻科技服务有限公司</a> </li>
                            <li><a href='http://www.yyxxkj.cn/' target='_blank'>合肥奕云信息科技有限公司</a> </li>
                            <li><a href='http://www.gen-song.net/' target='_blank'>合肥井松自动化科技有限公司</a> </li>
                            <li><a href='http://www.hfrongke.com/' target='_blank'>合肥戎科信息技术开发有限公司</a> </li>
                            <li><a href='http://www.brightude.com/' target='_blank'>合肥博焱智能科技有限公司</a> </li>
                        </ul>
                    </div>
                    </div>
                </div> -->
            <div class="span4 col-xm-6 col-xs-12">
              <div class="footer_list">
                <div class="footer_cotact">
                  <div class="footer_cotact_title">联系方式：</div>
                  <ul>
                    <li>
                      <span class="footer_cotact_type">地址：</span
                      ><span class="footer_cotact_content"
                        >合肥市高新区习友路3333号中国（合肥）国际智能语音产业园研发中心楼611-52室</span
                      >
                    </li>
                    <li>
                      <span class="footer_cotact_type">电话：</span
                      ><span class="footer_cotact_content"
                        ><a href="tel:0551-65906197" class="call"
                          >0551-65906197</a
                        ></span
                      >
                    </li>
                    <li>
                      <span class="footer_cotact_type">网址：</span
                      ><span class="footer_cotact_content"
                        ><a href="http://www.cfmetau.cn" title="官网"
                          >http://www.cfmetau.cn</a
                        ></span
                      >
                    </li>
                    <li>
                      <span class="footer_cotact_type">邮箱：</span
                      ><span class="footer_cotact_content"
                        >chengfang@cfmetau.cn</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright">
          <a href="https://beian.miit.gov.cn" target="_blank">
            Copyright &copy; 2021-2029 版权所有 皖ICP备2022002181号-1
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      picData: [require("../assets/images/weixin.png")],
    };
  },
};
</script>
<style lang="less" rel="stylesheet/less" scoped>
.footer_content{
    display: flex;
    justify-content: space-around;
}
.foot-left{
    // width: 600px;
}
.quick_navigation{
    padding-left: 110px;
}
</style>
