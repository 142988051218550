
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const indexList = resolve => require(['../pages/IndexList.vue'], resolve);
// const product = resolve => require(['../pages/product/ProductList.vue'], resolve);
// const productDetails  = resolve => require(['../pages/product/ProductDetails.vue'], resolve);
const about = resolve => require(['../pages/about/AboutList.vue'], resolve);
const job = resolve => require(['../pages/about/JobList.vue'], resolve);
const coreTechnology = resolve => require(['../pages/technology/CoreTechnology.vue'], resolve);
const technicalDetails = resolve => require(['../pages/technology/TechnicalDetails.vue'], resolve);
const nmews = resolve => require(['../pages/news/NewsList.vue'], resolve);
const newsDetail = resolve => require(['../pages/news/DetailList.vue'], resolve);
const proDetail = resolve => require(['../pages/prodetail/proDetail.vue'], resolve);
const soultionList = resolve => require(['../pages/soultion/soultionList.vue'], resolve);
const productList = resolve => require(['../pages/product/productList.vue'], resolve);
const productAiList = resolve => require(['../pages/productAi/productAiList.vue'], resolve);
const payLoad = resolve => require(['../pages/payload/payLoad.vue'], resolve);
const mobileSdk = resolve => require(['../pages/mobilesdk/mobileSdk.vue'], resolve);
const onBoard = resolve => require(['../pages/onboard/onBoard.vue'], resolve);
const dNss = resolve => require(['../pages/dnss/dNss.vue'], resolve);
const mSml = resolve => require(['../pages/msml/mSml.vue'], resolve);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  //首页
  routes: [{
    path: '/',
    name: 'IndexList',
    component: indexList,
    meta: {
      title: '首页',    // 菜单标题
      hasSubMenu: false, // 是否包含子菜单，false 没有子菜单；true 有子菜单

    }, 
  },

  //产品
  {
    path: '/soultionList',
    name: 'soultionList',
    component: soultionList,
    meta: {
      title: '产品介绍',
      hasSubMenu: true,
    },


  },

  {
    path: '/productList',
    name: 'productList',
    component: productList,
    meta: {
      title: '产品介绍',
      hasSubMenu: true,
    },
  },
  {
    path: '/productAiList',
    name: 'productAiList',
    component: productAiList,
    meta: {
      title: '产品介绍',
      hasSubMenu: true,
    },
  },

  {
    path: '/technology/payload',
    name: 'paoLoad',
    component: payLoad,
    meta: {
      title: '核心技术',
      hasSubMenu: true,
    },
  },

  {
    path: '/technology/mobileSdk',
    name: 'mobileSdk',
    component: mobileSdk,
    meta: {
      title: '核心技术',
      hasSubMenu: true,
    },
  },

  {
    path: '/technology/onBoard',
    name: 'onBoard',
    component: onBoard,
    meta: {
      title: '核心技术',
      hasSubMenu: true,
    },
  },
  {
    path: '/technology/dNss',
    name: 'dNss',
    component: dNss,
    meta: {
      title: '核心技术',
      hasSubMenu: true,
    },
  },
  {
    path: '/technology/mSml',
    name: 'mSml',
    component: mSml,
    meta: {
      title: '核心技术',
      hasSubMenu: true,
    },
  },


 
  //关于成方
  {
    path: '/AboutList',
    name: 'AboutList',
    component: about
  },
  // {
  //   path: '/soultionList',
  //   name: 'soultionList',
  //   component: soultionList
  // },
  {
    path: '/proDetail',
    name: 'proDetail',
    component: proDetail
  },
 
  //核心技术
  {
    path: '/CoreTechnology',
    name: 'coreTechnology',
    component: coreTechnology,
    meta: {
        title: '核心技术',
        hasSubMenu: true,
      },
      children: [
        // 核心技术
        {
          path: '/technology/TechnicalDetails',
          name: 'technicalDetails',
          meta: {
            title: '核心技术详细页',    // 菜单标题,
            hasSubMenu: false    // 是否包含子菜单
          },
          component: technicalDetails,
        },
      ]
  },
  //媒体中心
  {
    path: '/NewsList',
    name: 'NewsList',
    component: nmews,
    meta: {
        title: '媒体中心',
        hasSubMenu: true,
      },
      children: [
        // 媒体中心
        {
          path: '/news/DetailList',
          name: 'DetailList',
          meta: {
            title: '媒体中心详细页',    // 菜单标题,
            hasSubMenu: false    // 是否包含子菜单
          },
          component: newsDetail,
        },
      ]
  },
  //联系我们
  {
    path: '/JobList',
    name: 'JobList',
    component: job
  }
  ]
})
export default router